small
<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-overlay :show="loading">
            <validation-observer ref="simpleRules">
              <b-form class="container">
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label>Cikkszám</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.article_number"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Név</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.name"
                            type="text"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <label>Mennyiség egység</label>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <v-select
                            v-model="formData.unit"
                            label="title"
                            :options="quantityUnits"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Nettó beszerzési egységár</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.unit_price"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Beszerzési ár dátuma</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.sale_price_date"
                            type="date"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Profit</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.profit"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Áfa</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            v-model="formData.vat"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <label>Kategória</label>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <v-select
                            v-model="selectedCategory"
                            label="name"
                            :options="allCategories()"
                            :state="errors.length > 0 ? false : null"
                            @input="selectId($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-checkbox
                            v-model="formData.active"
                            value="1"
                            unchecked-value="0"
                        >
                          Kasszán megjelenik
                        </b-form-checkbox>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit button -->
                  <b-col cols="12">
                    <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="validationForm"
                    >
                      Mentés
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-card v-if="stocks">
          <b-overlay :show="loading">
            <h4>Raktárkészlet</h4>
            <validation-observer ref="simpleRules">
              <b-form class="container">
                <b-row v-for="stock in stocks" :key="stock.in">
                  <b-col md="12">
                    <b-form-group>
                      <label>{{ stock.in }}</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-input-group :append="stock.unit">
                          <b-form-input
                              v-model="stock.value"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- submit button -->
                <b-col cols="12">
                  <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="saveStocks"
                  >
                    Mentés
                  </b-button>
                </b-col>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
        <b-card v-else>
          <h4>A termék sehol sincs raktáron!</h4>
        </b-card>

        <b-card>
          <b-overlay :show="warehousesLoading">
            <h4>Árak</h4>
            <validation-observer ref="priceObserver">
              <b-form class="container">
                <b-row>
                  <b-col md="12">
                    <label>Raktár</label>
                    <b-form-group>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <v-select
                            v-model="selectedWarehouse"
                            label="name"
                            :options="allWarehouses()"
                            :state="errors.length > 0 ? false : null"
                            @input="selectWarehouse($event)"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label>Új értékesítési ár:</label>
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-input-group :append="'Ft'">
                          <b-form-input
                              v-model="newPrice"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- submit button -->
                <b-col cols="12">
                  <b-button
                      variant="primary"
                      type="submit"
                      @click.prevent="overridePrice"
                  >
                    Mentés
                  </b-button>
                </b-col>

                <b-col cols="12">
                  <div class="mt-4">Ár felülírások:</div>
                  <div v-for="price in allPriceOverrides()" :key="price.id">
                    <b-row>
                      <b-col md="6">
                        <b-form-group>
                          <label>Raktár</label>
                          <b-form-input
                              v-model="price.warehouse_name"
                              type="text"
                              readonly
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <b-form-group>
                          <label>Ár</label>
                          <b-input-group :append="'Ft'">
                            <b-form-input
                                v-model="price.price"
                                type="number"
                                readonly
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" class="remove-btn">
                        <b-form-group>
                          <b-button
                              variant="danger"
                              @click="deleteOverride(price)"
                          >
                            Törlés
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-form>
            </validation-observer>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <!-- table -->
      <b-overlay :show="historyLoading">
        <vue-good-table
            :columns="columns"
            :rows="getProductHistory"
            :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
            :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: description -->
            <span v-if="props.column.field === 'description'">
              <div v-html="props.row.description"></div>
            </span>
          </template>
        </vue-good-table>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import {required} from "@validations";
import {mapGetters, mapActions} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import vSelect from "vue-select";
import {
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    VueGoodTable,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BInputGroupAppend,
    BForm,
    BRow,
    BInputGroup,
    BCard,
    BCol,
    BButton,
    BFormCheckbox,
    vSelect,
    BOverlay,
  },
  data() {
    return {
      formData: {
        id: null,
        article_number: null,
        name: null,
        unit: null,
        unit_price: null,
        sale_price_date: null,
        categories: null,
        profit: null,
        vat: null,
        active: null,
      },
      selectedCategory: null,
      selectedWarehouse: null,
      newPrice: null,
      loading: false,
      quantityUnits: ["db", "kg", "l"],
      pageLength: 10,
      columns: [
        {
          label: "Dátum",
          field: "created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Dátum keresés",
          },
        },
        {
          label: "Esemény típusa",
          field: "type",
          filterOptions: {
            enabled: true,
            placeholder: "Típus keresés",
          },
        },
        {
          label: "Esemény leírása",
          field: "description",
          filterOptions: {
            enabled: false,
            placeholder: "Leírása keresés",
          },
        },
        {
          label: "Felhasználó",
          field: "user.name",
          filterOptions: {
            enabled: true,
            placeholder: "Felhasználó keresés",
          },
        },
        {
          label: "Fizetési mód",
          field: "paymentType",
          filterOptions: {
            enabled: true,
            placeholder: "Fizetési mód keresés",
          },
        },
      ],
      searchTerm: "",
      historyLoading: false,
      warehousesLoading: false,
      required,
      stocks: [],
    };
  },
  computed: {
    ...mapGetters(["getProductHistory"]),
  },
  methods: {
    ...mapGetters([
      "allCategories",
      "allWarehouses",
      "allPriceOverrides"
    ]),
    ...mapActions([
      "getProduct",
      "updateProduct",
      "updateStocks",
      "fetchCategories",
      "fetchWarehouses",
      "fetchProductHistory",
      "overrideProductPriceByWarehouse",
      "getProductPriceOverridesByWarehouse",
      "removeOverrideProductPriceByWarehouse"
    ]),
    initWarehouses() {
      this.warehousesLoading = true
      this.fetchWarehouses().then(res => {
        this.warehousesLoading = false
      })
    },
    selectWarehouse(e) {
      this.selectedWarehouse = e.id
    },
    initCategories() {
      this.loading = true;
      this.fetchCategories().then((res) => {
        this.loading = false;
      });
    },
    initProductHistory() {
      this.historyLoading = true;
      this.fetchProductHistory(this.$route.params.id).then((res) => {
        this.historyLoading = false;
      });
    },
    saveStocks() {
      let data = {
        id: this.formData.id,
        stocks: this.stocks,
      };
      this.updateStocks(data).then((res) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Sikeres mentés",
            icon: "EditIcon",
            variant: "success",
          },
        });
      });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.updateProduct(this.formData).then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sikeres mentés",
                icon: "EditIcon",
                variant: "success",
              },
            });
          });
        }
      });
    },
    initProduct() {
      this.loading = true;
      this.getProduct(this.$route.params.id).then((res) => {
        this.formData.id = res.id;
        this.formData.article_number = res.article_number;
        this.formData.name = res.name;
        this.formData.unit = res.unit;
        this.formData.unit_price = res.unit_price;
        this.formData.profit = res.profit;
        this.formData.vat = res.vat;
        this.formData.active = res.active;
        this.formData.sale_price_date = res.sale_price_date;
        this.selectedCategory = res.categories.slice(-1)[0];
        this.stocks = res.quantity;
        this.loading = false;
      });
    },
    selectId(e) {
      this.formData.categories = e.id;
    },
    deleteOverride(price) {
      const payload = {
        product_id: parseFloat(this.$route.params.id),
        warehouse_id: price.warehouse_id,
      };
      this.removeOverrideProductPriceByWarehouse(payload).then((res) => {
        this.getProductPriceOverridesByWarehouse(this.$route.params.id);
      });
    },
    overridePrice() {
      this.$refs.priceObserver.validate().then((success) => {
        if (success) {
          this.overrideProductPriceByWarehouse({
            warehouse_id: this.selectedWarehouse,
            product_id: parseFloat(this.$route.params.id),
            price: parseFloat(this.newPrice),
          }).then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Sikeres mentés",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getProductPriceOverridesByWarehouse(this.$route.params.id);
          });
        }
      });
    },
  },
  created() {
    this.initCategories();
    this.initProduct();
    this.initProductHistory();
    this.initWarehouses();
    this.getProductPriceOverridesByWarehouse(this.$route.params.id);
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.remove-btn {
  display: flex;
  align-items: end;
}
</style>
