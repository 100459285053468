var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"container"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Cikkszám")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.article_number),callback:function ($$v) {_vm.$set(_vm.formData, "article_number", $$v)},expression:"formData.article_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Név")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('label',[_vm._v("Mennyiség egység")]),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.quantityUnits},model:{value:(_vm.formData.unit),callback:function ($$v) {_vm.$set(_vm.formData, "unit", $$v)},expression:"formData.unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Nettó beszerzési egységár")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.unit_price),callback:function ($$v) {_vm.$set(_vm.formData, "unit_price", $$v)},expression:"formData.unit_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Beszerzési ár dátuma")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"date","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.sale_price_date),callback:function ($$v) {_vm.$set(_vm.formData, "sale_price_date", $$v)},expression:"formData.sale_price_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Profit")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.profit),callback:function ($$v) {_vm.$set(_vm.formData, "profit", $$v)},expression:"formData.profit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Áfa")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.vat),callback:function ($$v) {_vm.$set(_vm.formData, "vat", $$v)},expression:"formData.vat"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('label',[_vm._v("Kategória")]),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.allCategories(),"state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.selectId($event)}},model:{value:(_vm.selectedCategory),callback:function ($$v) {_vm.selectedCategory=$$v},expression:"selectedCategory"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"value":"1","unchecked-value":"0"},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}},[_vm._v(" Kasszán megjelenik ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" Mentés ")])],1)],1)],1)],1)],1)],1)],1),_c('b-col',[(_vm.stocks)?_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('h4',[_vm._v("Raktárkészlet")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"container"},[_vm._l((_vm.stocks),function(stock){return _c('b-row',{key:stock.in},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v(_vm._s(stock.in))]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":stock.unit}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(stock.value),callback:function ($$v) {_vm.$set(stock, "value", $$v)},expression:"stock.value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveStocks.apply(null, arguments)}}},[_vm._v(" Mentés ")])],1)],2)],1)],1)],1):_c('b-card',[_c('h4',[_vm._v("A termék sehol sincs raktáron!")])]),_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.warehousesLoading}},[_c('h4',[_vm._v("Árak")]),_c('validation-observer',{ref:"priceObserver"},[_c('b-form',{staticClass:"container"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('label',[_vm._v("Raktár")]),_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.allWarehouses(),"state":errors.length > 0 ? false : null},on:{"input":function($event){return _vm.selectWarehouse($event)}},model:{value:(_vm.selectedWarehouse),callback:function ($$v) {_vm.selectedWarehouse=$$v},expression:"selectedWarehouse"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',[_vm._v("Új értékesítési ár:")]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"append":'Ft'}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null},model:{value:(_vm.newPrice),callback:function ($$v) {_vm.newPrice=$$v},expression:"newPrice"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.overridePrice.apply(null, arguments)}}},[_vm._v(" Mentés ")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-4"},[_vm._v("Ár felülírások:")]),_vm._l((_vm.allPriceOverrides()),function(price){return _c('div',{key:price.id},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Raktár")]),_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(price.warehouse_name),callback:function ($$v) {_vm.$set(price, "warehouse_name", $$v)},expression:"price.warehouse_name"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',[_c('label',[_vm._v("Ár")]),_c('b-input-group',{attrs:{"append":'Ft'}},[_c('b-form-input',{attrs:{"type":"number","readonly":""},model:{value:(price.price),callback:function ($$v) {_vm.$set(price, "price", $$v)},expression:"price.price"}})],1)],1)],1),_c('b-col',{staticClass:"remove-btn",attrs:{"md":"3"}},[_c('b-form-group',[_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return _vm.deleteOverride(price)}}},[_vm._v(" Törlés ")])],1)],1)],1)],1)})],2)],1)],1)],1)],1)],1)],1),_c('b-card',[_c('b-overlay',{attrs:{"show":_vm.historyLoading}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.getProductHistory,"pagination-options":{
          enabled: true,
          perPage: _vm.pageLength,
        },"search-options":{
          enabled: true,
          externalQuery: _vm.searchTerm,
        }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'description')?_c('span',[_c('div',{domProps:{"innerHTML":_vm._s(props.row.description)}})]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }